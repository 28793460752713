body {
  margin: 0;
  padding:10px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiInputBase-root {
  display: inline !important;
  color: rgba(0, 0, 0, 0.54) !important;
  overflow: hidden !important;
}

.MuiInputBase-adornedEnd:hover .MuiInputAdornment-positionEnd,
.MuiInputBase-adornedEnd:focus .MuiInputAdornment-positionEnd,
.MuiInputBase-adornedEnd:active .MuiInputAdornment-positionEnd,
.Mui-focused .MuiInputAdornment-positionEnd {
  visibility: hidden !important;
}
